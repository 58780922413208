import logo from './logo.svg';
import './App.css';
import { Button, Menu, Segment, Sidebar } from 'semantic-ui-react'

function App() {
  return (
    <div className="App">
    
      <Sidebar.Pushable as={Segment}>
        <Sidebar as={Menu} animation='overlay' visible vertical>
          <Menu.Item as='a'>
            Home
          </Menu.Item>
          <Menu.Item as='a'>
            Page1
          </Menu.Item>
          <Menu.Item as='a'>
            Page2
          </Menu.Item>
          <Menu.Item as='a'>
            Page3
          </Menu.Item>
        </Sidebar>

      <Sidebar.Pusher>
         <Segment basic>

          <header className="App-header">
            <img src={logo} className="App-logo" alt="logo" />
            <p>
            <Button color='red' animated='fade'>
              <Button.Content visible>Please Click Here</Button.Content>
              <Button.Content hidden>NOOOOO!!</Button.Content>
            </Button>
            </p>
          </header>
        </Segment>
    </Sidebar.Pusher>
    </Sidebar.Pushable>
    </div>
  );
}

export default App;
